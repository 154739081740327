import React, { useEffect, useRef, useState } from "react";
// import currentEventImageSrc from "../assets/img/events.jpg";
// import videoIcon from "../assets/img/video.svg";
import fallFestival from "../assets/img/fall-festival.png";
import waterView from "../assets/img/curevent/water-view.jpeg";
import patriotic from "../assets/img/curevent/patriotic.jpeg";
import natureView from "../assets/img/curevent/nature-view.jpeg";

interface Event {
	image: string; //src / path to image
	caption: string;
}
export interface CurrentEventsProps {
	// Events: Event[];
	intervalTime: number;
}

//TO DO
//create controller
//have each carousel use the same interface

function CurrentEvents(props: CurrentEventsProps) {
// function CurrentEvents() {
	const [currImage, setCurrImage] = useState("");
	// const { Events, intervalTime } = props;
	const e1: Event = { image: fallFestival, caption: "" };
	const e2: Event = { image: patriotic, caption: "" };
	const e3: Event = { image: natureView, caption: "" };
	const e4: Event = { image: waterView, caption: "" };
	const Events: Event[] = [e1, e2, e3, e4];
  const {intervalTime} = props;
	const imageIndex_ref = useRef(0);

	useEffect(() => {
		updateImage(); //prevent initial delay
		const interval = setInterval(() => {
			updateImage();
		}, intervalTime); //30 sec 30000
		return () => clearInterval(interval);
		// eslint-disable-next-line
	}, []);

	function updateImage() {
		if (Events.length <= 0) return;
		if (imageIndex_ref.current >= Events.length) {
			imageIndex_ref.current = 0;
		}
		//the WP data
		setCurrImage(Events[imageIndex_ref.current].image);
		imageIndex_ref.current++;
	}

	return (
		<div className="container">
			<div
				className="video opt"
				style={{ backgroundImage: `url(${currImage})` }}
			>
				<h3 className="white">Current Events</h3>
				{/*	<img src={videoIcon} className="video-icon" alt="video icon" /> */}
			</div>
		</div>
	);
}

export default CurrentEvents;
