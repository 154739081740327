import { useEffect, useState } from "react";
//Components
import BusComponent, { BusComponentProps } from "./BusComponent";
import { BusStopIds } from "../views/LocationDisplay/Location.Interface";
//Data Imports
import fetchBusData from "./fetchBusData";
import { WaterTaxiSchedule } from "../data/WaterTaxiSchedule";

//TO DO
//FOR TRANSIT DIS ALSO
//MOVE THIS FUNCTIONALITY TO BACKEND
//converting bus api -> our needed output

interface BusIntervals {
  readonly api: number;
  readonly metroDisplay: number;
  readonly theBusDisplay: number;
}
interface BusControllerProps {
  readonly busStopIds: BusStopIds;
  readonly intervals: BusIntervals;
}

function BusController(props: BusControllerProps) {
  // console.log("Bus controller props:::", props);
  const { busStopIds, intervals } = props;

  //state for render
  const [metroCircleArr, setMetroCircleArr] = useState<
    BusComponentProps[]
  >(
    [],
  );
  const [theBusCircleArr, setTheBusCircleArr] = useState<
    BusComponentProps[]
  >(
    [],
  );

  //get new bus position information
  useEffect(() => {
    getBusData();
    const interval = setInterval(() => {
      getBusData();
    }, intervals.api);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busStopIds, intervals.api]);

  //=============================================
  //      Update Display State Functions
  //=============================================

  useEffect(() => {
    const interval = setInterval(() => {
      function updateMetroDisplay() {
        let lastElement = metroCircleArr[metroCircleArr.length - 1];
        popMetroCircleArr();
        pushMetroCircleArr(lastElement);
        // console.log("Updating Metro Display", lastElement);
        // console.log(metroCircleArr);
      }
      updateMetroDisplay();
    }, intervals.metroDisplay);

    return () => {
      clearInterval(interval);
    };
  }, [metroCircleArr, intervals.metroDisplay]);

  useEffect(() => {
    const interval = setInterval(() => {
      function updateTheBusDisplay() {
        let lastElement = theBusCircleArr[theBusCircleArr.length - 1];
        popTheBusCircleArr();
        pushTheBusCircleArr(lastElement);
        // console.log("Updating the Bus Display", lastElement);
        // console.log(theBusCircleArr);
      }
      updateTheBusDisplay();
    }, intervals.theBusDisplay);

    return () => {
      clearInterval(interval);
    };
  }, [theBusCircleArr, intervals.theBusDisplay]);

  //=============================================
  //          Helper Functions
  //=============================================

  async function getBusData() {
    let response = await fetchBusData(busStopIds);
    setMetroCircleArr(response.metro);
    setTheBusCircleArr(response.theBus);
  }
  function pushMetroCircleArr(element: BusComponentProps) {
    setMetroCircleArr((currArr) => [element, ...currArr]);
  }
  function popMetroCircleArr() {
    setMetroCircleArr((currArr) => currArr.slice(0, currArr.length - 1));
  }
  function pushTheBusCircleArr(element: BusComponentProps) {
    setTheBusCircleArr((currArr) => [element, ...currArr]);
  }
  function popTheBusCircleArr() {
    setTheBusCircleArr((currArr) => currArr.slice(0, currArr.length - 1));
  }

  // eslint-disable-next-line
  function easyDateUnix(timestamp: number) {
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    const time = new Date(timestamp * 1000);
    return time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  return (
    <div>
      <div className="loc-header">
        <div className="loc-header-col lhcl">
          <strong>Route</strong>
        </div>

        <div className="loc-header-col lhclm">
          <strong>Arriving In</strong>
        </div>
        <div className="loc-header-col lhcrm">
          <strong>Scheduled Arrival Time</strong>
        </div>
        <div className="loc-header-col lhclr">
          <strong>Heading To</strong>
        </div>
      </div>
      {metroCircleArr.length > 0
        ? (
          <>
            <BusComponent {...metroCircleArr[0]} />
          </>
        )
        : <></>}
      {metroCircleArr.length > 1
        ? (
          <>
            <BusComponent {...metroCircleArr[1]} />
          </>
        )
        : <></>}

      {theBusCircleArr.length > 0
        ? (
          <>
            <BusComponent {...theBusCircleArr[0]} />
          </>
        )
        : <></>}
    </div>
  );
}

export default BusController;
