//components
import LocationDisplayHeader from "./LocationDisplayHeader";
import CurrentEvents from "../../components/CurrentEvents";
import ThingsToDo from "../../components/ThingsToDo";
import BusController from "../../components/BusController";
import WaterTaxi from "../../components/WaterTaxi";

//interfaces and props
import { LocationDisplayHeaderProps } from "./LocationDisplayHeader";
import { LocationInterface } from "./Location.Interface";
//config
import IntervalTimes from "../../config/IntervalTimes.json";
//styles and images
import "../../styles/LocationDisplayStyles.css";
import nhLogo2 from "../../assets/img/logo2.png";
import marylandLogo from "../../assets/img/maryland.png";
//FeaturedItems
import FeaturedItems from "../../components/FeaturedItems";

interface LocationDisplayProps {
  readonly locationData: LocationInterface;
}

function LocationDisplay({ locationData }: LocationDisplayProps) {
  const { locationName, busStopIds, gioLocation } = locationData;
  // console.log("locationData", locationData);
  const headerData: LocationDisplayHeaderProps = {
    gioLocation,
    locationName,
    intervalTime: IntervalTimes.weatherAPIInterval,
  };
  return (
    <>
      {/* {console.log("bus stop ids via location display", busStopIds)} */}
      <LocationDisplayHeader {...headerData} />
      <main>
        <section id="section1">
          {/* <CurrentEventsController props={}/> */}
          <CurrentEvents intervalTime={IntervalTimes.currentEvents} />
        </section>
        <section id="section2">
          <div className="container">
            <ThingsToDo />
            <div className="block">
              <BusController
                busStopIds={busStopIds}
                intervals={IntervalTimes.busIntervals}
              />
              {/* <WaterTaxi /> */}
            </div>
            <div className="logos">
              <img
                src={nhLogo2}
                className="logo1"
                alt="main logotype - National Harbor"
              />
              <img
                src={marylandLogo}
                className="logo2"
                alt="Maryland - office of tourism"
              />
            </div>
          </div>
        </section>
      </main>
      <footer className="mtm fl">
        <div className="container">
          <FeaturedItems intervalTime={IntervalTimes.featuredItems} />
          <div className="info va jc wc">
            <h3 className="cap">Contact Info:</h3>
            <div className="info-text">
              <p className="name">
                <strong>National Harbor</strong>
              </p>
              <p className="company-desc">
                165 Waterfront Street, National Harbor, MD 20745
                <br />
                <a href="tel:8776285427">877.628.5427</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default LocationDisplay;
