import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LocationController from "./views/LocationDisplay/LocationController";
function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<LocationController />} />
				<Route path="/:locationId" element={<LocationController />} />
			</Routes>
		</Router>
	);
}

export default App;
