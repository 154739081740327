// import React from "react";
import patterns from "../assets/img/patterns.svg";
import thing1 from "../assets/img/things/thing1.jpeg";
import thing2 from "../assets/img/things/thing2.jpeg";
import thing3 from "../assets/img/things/thing3.jpeg";

function ThingsToDo() {
  //each thing will be an ImageCarousel
  return (
    <div
      className="block blue patterns"
      style={{ backgroundImage: `url(${patterns})` }}
    >
      <h3 className="tc white">Things To Do</h3>
      <div className="fl things">
        <div className="w3">
          <div className="fl jc">
            <strong className="white">Colonial Market & Fair at George</strong>
          </div>
          <div
            className="image"
            style={{ backgroundImage: `url(${thing1})` }}
          ></div>
        </div>
        <div className="w3">
          <div className="fl jc ">
            <strong className="white">George Washington Whiskey Tasting</strong>
          </div>

          <div
            className="image"
            style={{ backgroundImage: `url(${thing2})` }}
          ></div>
        </div>
        <div className="w3">
          <div className="fl jc ">
            <strong className="white">Movies on the Potomac</strong>
          </div>

          <div
            className="image"
            style={{ backgroundImage: `url(${thing3})` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default ThingsToDo;
