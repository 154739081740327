import React, { useEffect, useState } from "react";
import LocationDisplay from "./LocationDisplay";
import * as defaultLocationData from "../../data/default_LocationData.json";
import { LocationInterface } from "./Location.Interface";
import { StopDisplayApi } from "../../utils/StopDisplayApi";
import { useParams } from "react-router-dom";

function LocationController() {
	const [isLoading, setIsLoading] = useState(true);
	const [locationData, setLocationData] =
		useState<LocationInterface>(defaultLocationData);
	const { locationId } = useParams();

	useEffect(() => {
		async function getLocationData() {
			try {
				const response = await StopDisplayApi.get(
					`/location/${locationId}`
				);
				//TO DO -- in location lambda though
				/*
				change error responses
			*/
				//till above is done
				if (typeof response.data === "string") {
					setLocationData(defaultLocationData);
					setIsLoading(false);
					throw new Error("Empty Body Response");
				}
				if (response.status === 200) {
					setLocationData(response.data);
					setIsLoading(false);
				} else {
					setLocationData(defaultLocationData);
					setIsLoading(false);
				}
			} catch (error) {
				console.error(error);
			}
		}
		if (locationId !== "" || typeof locationId != "string") {
			getLocationData();
		} else {
			setLocationData(defaultLocationData);
			setIsLoading(false);
		}
	}, [locationId]);

	// console.log("location controller", locationData);
	return (
		<div>
			{isLoading ? (
				<></>
			) : (
				<LocationDisplay locationData={locationData} />
			)}
		</div>
	);
}

// <LocationDisplay locationData={defaultLocationData} />

export default LocationController;
