import React, { useEffect, useRef, useState } from "react";
// import option1 from "../assets/img/option1.jpg";
// import option2 from "../assets/img/option2.jpg";
// import option3 from "../assets/img/option3.jpg";
//
import benjerry from "../assets/img/benjerry.jpg";
import streetfood from "../assets/img/streetfood.jpg";
import hyattplace from "../assets/img/hyattplace.jpg";

import dessert from "../assets/img/options/dessert.jpeg";
import drinks from "../assets/img/options/drinks.jpeg";
import trays from "../assets/img/options/trays.jpeg";

interface Event {
  image: string; //src / path to image
  caption: string;
}
export interface FeaturedItemsProps {
  // Events: Event[];
  readonly intervalTime: number;
}

function FeaturedItems(props: FeaturedItemsProps) {
  const e1: Event = {
    image: hyattplace,
    caption: "Bar Harbor At Hyatt Place",
  };
  const e2: Event = { image: benjerry, caption: "Ben & Jerry's" };
  const e3: Event = { image: streetfood, caption: "Bombay Street Food" };
  const e4: Event = { image: dessert, caption: "Delicous Desserts" };
  const e5: Event = { image: drinks, caption: "Incredible Drinks" };
  const e6: Event = { image: trays, caption: "Enticing Cuisine" };
  const Events1: Event[] = [e1, e2, e3];
  const Events2: Event[] = [e4, e5, e6];
  const {intervalTime} = props;
  const [currSet, setCurrSet] = useState<Event[]>(Events1);
  const imageIndex_ref = useRef(0);

  useEffect(() => {
    updateImage(); //prevent initial delay
    const interval = setInterval(() => {
      updateImage();
    }, intervalTime); //30 sec 30000
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  function updateImage() {
    if (imageIndex_ref.current === 0) {
      setCurrSet(Events1);
      imageIndex_ref.current = 1;
    } else {
      setCurrSet(Events2);
      imageIndex_ref.current = 0;
    }
  }

  return (
    <div className="items fl hsmall">
      <div className="item w3">
        <div className="item-title">
          <div className="titles tc">
            <h3 className="white">{currSet[0].caption}</h3>
          </div>
        </div>
        <div
          className="image"
          style={{ backgroundImage: `url(${currSet[0].image})` }}
        >
        </div>
      </div>

      <div className="item w3">
        <div className="item-title">
          <div className="titles tc">
            <h3 className="white">{currSet[1].caption}</h3>
          </div>
        </div>
        <div
          className="image"
          style={{ backgroundImage: `url(${currSet[1].image})` }}
        >
        </div>
      </div>

      <div className="item w3">
        <div className="item-title">
          <div className="titles tc">
            <h3 className="white">{currSet[2].caption}</h3>
          </div>
        </div>
        <div
          className="image"
          style={{ backgroundImage: `url(${currSet[2].image})` }}
        >
        </div>
      </div>
    </div>
  );
}

export default FeaturedItems;
