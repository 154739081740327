import React from "react";
import metro from "../assets/img/metro.png";
import theBus from "../assets/img/TheBuslogo.png";
import clock from "../assets/img/clock2.svg";

export interface BusComponentProps {
  agencyIcon: string;
  agencyTitle: string;
  departed: boolean;
  minutes: number;
  routeId: string;
  headsign: string;
  estArrivalTime: Date;
}

function BusComponent(props: BusComponentProps) {
  const {
    agencyIcon,
    // agencyTitle,
    departed,
    minutes,
    routeId,
    headsign,
    estArrivalTime,
  } = props;
  // if (agencyIcon === "metro")
  // console.log(props);

  let icon = "";
  switch (agencyIcon) {
    case "metro":
      icon = metro;
      break;
    default:
      icon = theBus;
      break;
  }
  function noTheBusEnRoute() {
    return (
      <div className="location fl">
        <div className="loc-icon-sm cent">
          <img src={icon} alt="metro logotype" />
        </div>
        <div className="loc-block-c fl fl11">
          <div className="loc-left fl11">
            <div className="loc-desc jc">
              <p>
                <strong>
                  No Buses Enroute Please Stand By For Further Updates
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (
    !departed && agencyIcon === "theBus"
  ) {
    return noTheBusEnRoute();
  }

  //======
  // return noTheBusEnRoute();
  //======
  return (
    <div className="location fl">
      <div className="loc-icon-sm cent">
        <img src={icon} alt="metro logotype" />
      </div>
      <div className="loc-block-c fl fl11">
        <div className="loc-left fl11">
          <div className="loc-desc jc">
            <p>
              <strong>{routeId}</strong>
            </p>
          </div>
        </div>
        <div className="loc-right fl11">
          <div className="loc-desc jc">
            {departed
              ? (
                <p>
                  <strong>{minutes} minutes</strong>
                </p>
              )
              : (
                <img
                  src={clock}
                  className="black time"
                  alt="time"
                />
              )}
          </div>
        </div>
        <div className="loc-right fl11">
          <div className="loc-desc jc">
            <p className="mt0">
              <strong>
                {estArrivalTime.toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
              </strong>
            </p>
          </div>
        </div>
        <div className="loc-right fl11">
          <div className="loc-desc jc">
            <p>
              <strong>{headsign}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusComponent;
