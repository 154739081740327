import { BusStopIds } from "../views/LocationDisplay/Location.Interface";
import { StopDisplayApi } from "../utils/StopDisplayApi";
import { BusComponentProps } from "./BusComponent";
import { washMetroOncomingBus, washMetroScheduledBus } from "./Bus.interface";

const METRO_DEFAULT_NUM_DEPARTING = 2;

export default async function fetchBusData(busStopIds: BusStopIds) {
  //=============================================
  //         Get Bus Schedule Functions
  //=============================================
  async function getTheBusSchedule() {
    // console.log(
    // 	"TheBus load function called TheBus Stop Id: ",
    // 	busStopIds.theBus
    // );

    // check if the selected stop serves TheBus stops
    if (busStopIds.theBus === null) {
      console.warn("no TheBus stop id provided", busStopIds.theBus);
      return [];
    }
    try {
      let response = await StopDisplayApi.get(
        "/buses/thebus/predictions",
        {
          params: { stop: busStopIds.theBus },
          // params: { stop: 38384 },
        },
      ); // oxon hill 33284

      // console.log(
      // 	"RESPONSE",
      // 	response.data.data.predictionsData,
      // 	busStopIds.theBus
      // );
      if (response.status !== 200) return [];
      //successful request
      let accumulatorArr: BusComponentProps[] = [];
      response.data.data.predictionsData.forEach((route: any) => {
        route.destinations.forEach((direction: any) => {
          direction.predictions.forEach((bus: any) => {
            accumulatorArr.push({
              agencyIcon: "theBus",
              agencyTitle: "The Bus - Prince George's County",
              departed: true,
              routeId: route.routeId,
              headsign: direction.headsign,
              minutes: bus.min,
              estArrivalTime: new Date(bus.time * 1000), // multiplied by 1000 so that the argument is in milliseconds, not seconds.
            });
          });
        });
      });
      // console.log(accumulatorArr);
      //if no buses enroute accumulatorArr is empty
      if (accumulatorArr.length === 0) {
        accumulatorArr.push({
          agencyIcon: "theBus",
          agencyTitle: "The Bus - Prince George's County",
          departed: false,
          routeId: "routeId",
          headsign: "headsign",
          minutes: -1,
          estArrivalTime: new Date(), // multiplied by 1000 so that the argument is in milliseconds, not seconds.
        });
      }
      return accumulatorArr;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async function getMetroSchedule() {
    // console.log(
    // 	"Wash Metro load function called Wash Metro Stop Id: ",
    // 	busStopIds.washMetro
    // );
    // check if the selected stop serves Wash Metro stops
    if (busStopIds.washMetro === null) {
      console.log("no Washington Metro Id provided");
      return [];
    }

    let nextMetroDeparting: washMetroScheduledBus[] = [];
    try {
      const response = await StopDisplayApi.get(
        "/buses/washmetro/predictions",
        {
          params: { stopID: busStopIds.washMetro },
        },
      );
      const departedMetroBuses: washMetroOncomingBus[] =
        response.data.Predictions;
      // console.log(departedMetroBuses);
      if (departedMetroBuses.length === 1) {
        //get the first to depart bus from the schedule for occupying the 2nd display div
        nextMetroDeparting = await getMetroNextDeparting(1);
      }
      // if (departedMetroBuses.length <= 0) {
      //get a number of buses that are first to depart from the schedule
      nextMetroDeparting = await getMetroNextDeparting(
        METRO_DEFAULT_NUM_DEPARTING,
      );
      // }
      // console.warn("nextMetroDeparting", nextMetroDeparting);
      // console.warn("departedMetroBuses", departedMetroBuses);
      //format and sort metro buses
      const formattedOnComing: BusComponentProps[] = departedMetroBuses.map(
        (bus: any) => {
          const timeNow = new Date();
          let estArrival = new Date();
          estArrival.setMinutes(timeNow.getMinutes() + bus.Minutes);
          return {
            agencyIcon: "metro",
            agencyTitle: "Washington Metropolitan Area Transit Authority",
            departed: true,
            minutes: bus.Minutes,
            routeId: bus.RouteID,
            headsign: bus.DirectionText,
            estArrivalTime: estArrival,
          };
        },
      );

      const formattedScheduled: BusComponentProps[] = nextMetroDeparting.map(
        (bus: any) => {
          const estArrival = new Date(bus.ScheduleTime);
          return {
            agencyIcon: "metro",
            agencyTitle: "Washington Metropolitan Area Transit Authority",
            departed: false,
            routeId: bus.RouteID,
            headsign: bus.TripHeadsign,
            estArrivalTime: estArrival,
            minutes: -1,
          };
        },
      );

      //combine the arrays and sort
      const formatted: BusComponentProps[] = formattedOnComing.concat(
        formattedScheduled,
      );
      // console.log("formatted", formatted);
      const result = formatted.sort(
        (a: BusComponentProps, b: BusComponentProps) =>
          a.estArrivalTime.getTime() - b.estArrivalTime.getTime(),
      );
      return result;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async function getMetroNextDeparting(
    numToReturn: number,
  ) {
    //check if the selected stop serves Wash Metro stops
    if (busStopIds.washMetro === null) {
      console.warn("no Washington Metro stop id provided");
      return [];
    }
    try {
      const response = await StopDisplayApi.get(
        "/buses/washmetro/stop-schedule",
        {
          params: { stopID: busStopIds.washMetro },
        },
      );
      const metroSchedule = response.data.ScheduleArrivals;
      // console.log(metroSchedule);
      //sort metroSchedule ScheduleTime against the current time, puts timeNowIndex before the first scheduled to depart
      //ScheduleTime Date and time (Eastern Standard Time) when the bus is scheduled to stop at this location.
      //Will be in YYYY-MM-DDTHH:mm:ss format (e.g.: 2014-10-27T13:17:00).
      //since the Metro API uses this format in local time not UTC, we have to offset the date.toISOString since it returns in UTC
      // let timeNow = new Date().toISOString().substring(0, 19);
      let timeNow = new Date();
      let offset = timeNow.getTimezoneOffset();
      timeNow.setUTCMinutes(timeNow.getMinutes() - offset);
      const timeNowString = timeNow.toISOString().substring(0, 19);
      metroSchedule.push({
        ScheduleTime: timeNowString,
      });
      metroSchedule.sort(
        (a: any, b: any) =>
          new Date(a.ScheduleTime).getTime() -
          new Date(b.ScheduleTime).getTime(),
      );
      // console.log("Metro Schedule ---", metroSchedule);
      let timeNowIndex = metroSchedule
        .map((bus: any) => {
          return bus.ScheduleTime;
        })
        .indexOf(timeNowString);
      let slicedScheduled = metroSchedule.slice(
        timeNowIndex + 1,
        timeNowIndex + numToReturn + 1,
      );
      // console.log(
      // 	"not dep sch",
      // 	slicedScheduled,
      // 	"full sch",
      // 	metroSchedule
      // );
      return slicedScheduled;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  return {
    theBus: await getTheBusSchedule(),
    metro: await getMetroSchedule(),
  };
}
