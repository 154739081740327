import React, { useEffect, useState } from "react";
import { GioLocation } from "./Location.Interface";
//Services
import { StopDisplayApi } from "../../utils/StopDisplayApi";
//logos
import nationHarborLogo from "../../assets/img/logo.png";
//default data
import * as defaultWeatherData from "../../data/default_WeatherData.json";

export interface LocationDisplayHeaderProps {
  readonly locationName: string;
  readonly gioLocation: GioLocation;
  readonly intervalTime: number;
}
interface WeatherDataInterface {
  temp: number;
  iconSrc: string;
}
function LocationDisplayHeader(headerData: LocationDisplayHeaderProps) {
  const { gioLocation, locationName, intervalTime } = headerData;
  const [weatherData, setWeatherData] =
    useState<WeatherDataInterface>(defaultWeatherData);
  const [clockState, setClockState] = useState("");
  const [dateState, setDateState] = useState("");

  //Manage Clock state
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  useEffect(() => {
    setTime();
    const interval = setInterval(() => {
      setTime();
    }, 1000); //1 second
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWeatherData();
    const interval = setInterval(() => {
      getWeatherData();
    }, intervalTime); //5 minutes (300000)
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);
  async function getWeatherData() {
    try {
      const response = await StopDisplayApi.get("/weather/forecast", {
        params: { lat: gioLocation.lat, lon: gioLocation.lon },
      });
      let iconCode = response.data.list[0].weather[0].icon;
      let formatted = {
        temp: Math.round(response.data.list[0].main.temp),
        iconSrc: `https://openweathermap.org/img/wn/${iconCode}@4x.png`,
      };
      setWeatherData(formatted);
    } catch (err) {
      console.error(err);
    }
  }

  function setTime() {
    const today = new Date();
    setClockState(
      today.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
    );

    let day = today.getDate();
    let month = months[today.getMonth()];
    let year = today.getFullYear();
    let formatted = month + ". " + day + ", " + year;
    setDateState(formatted);
  }

  return (
    <header>
      <div className="container wc">
        <div className="header-top fs">
          <div className="header-block fl11">
            <div className="block-cont va">
              <img
                src={weatherData.iconSrc}
                className="icon"
                alt="weather icon"
              />
              <p className="ff-sb fs3">
                {weatherData.temp}
                <sup>O</sup>F
              </p>
            </div>
          </div>
          <div className="header-title tc">
            <h3 className="up">{locationName}</h3>
          </div>
          <div className="header-block fl11 tr">
            <div className="block-cont">
              <p className="ff-sb fs3">{clockState}</p>
              <p className="ff-sb lsp sub">{dateState}</p>
            </div>
          </div>
        </div>
        <img src={nationHarborLogo} className="logo" alt="logotype" />
      </div>
    </header>
  );
}

export default LocationDisplayHeader;
